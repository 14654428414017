import React from 'react';

import * as GatsbyTypes from '../graphqlTypes';
import styled, { scale, baseline, BREAKPOINTS } from '../styled';
import { generateContactEventTrackingObject, useTrackingContext } from '../tracking';

import { IconPhoneHandset, IconMapMarker, IconMail } from './icons';
import { CenteredBoxMaxWidth } from './CenteredBox';
import Mask from './Mask';

const Background = styled.div`
  background-color: ${({ theme }): string => theme.color.green.m};
`;

const Layout = styled.div`
  padding: ${scale(2)} ${scale(1)};

  color: #fff;

  @media (min-width: ${BREAKPOINTS.m}) {
    padding-top: ${scale(4)};
    padding-bottom: ${scale(6)};
    margin-left: auto;
    max-width: ${scale(CenteredBoxMaxWidth.M * 0.45)};
    box-sizing: border-box;
  }
`;

const Link = styled.a`
  position: relative;
  text-decoration: none;

  color: #fff;
  margin-left: ${scale(2.5)};

  > svg {
    position: absolute;
    left: ${scale(-2.5)};
    top: 50%;

    color: #fff;

    transform: translateY(-50%);
    transition: color ${({ theme }): string => theme.timing.s};
    will-change: color;
  }

  &:hover {
    > svg {
      color: ${({ theme }): string => theme.color.green.s};
    }
  }
`;

const PhoneNumberLink = styled(Link)`
  ${baseline(26, 2, 'm')};

  @media (min-width: ${BREAKPOINTS.m}) {
    ${baseline(30, 2, 'l')};
  }
`;

const MarkerLink = styled(Link)`
  font-weight: normal;
`;

const SchedulesTable = styled.table`
  width: 100%;
  text-align: left;
  border-spacing: 0;

  caption {
    margin-bottom: ${scale(1)};

    font-weight: bold;
    text-align: left;
    ${baseline(26, 2, 'm')};

    @media (min-width: ${BREAKPOINTS.m}) {
      ${baseline(36, 2, 'l')};
    }
  }

  th {
    width: 50%;
    padding: 0;

    font-weight: normal;
  }

  td {
    width: 50%;
    padding: 0;

    font-weight: bold;
  }
`;

const i18nDays: {
  [key: string]: string;
} = {
  Monday: 'Lundi',
  Tuesday: 'Mardi',
  Wednesday: 'Mercredi',
  Thursday: 'Jeudi',
  Friday: 'Vendredi',
  Saturday: 'Samedi',
  Sunday: 'Dimanche',
};

const ContactInformation: React.FC<{
  phoneNumber: string;
  email: string;
  openingHours: GatsbyTypes.MarkdownRemarkFrontmatterOpeningHours[];
}> = (props) => {
  const { tag } = useTrackingContext();
  const phoneURL = `tel:+33${props.phoneNumber.substr(1)}`;
  const phoneEventObject = generateContactEventTrackingObject(
    'phone',
    'contact_page_phone_link',
    phoneURL
  );
  const handlePhoneOnClick = React.useCallback((): void => {
    /* istanbul ignore next */
    if (!tag) return;
    tag(phoneEventObject);
  }, [tag, phoneEventObject]);

  const mapsURL = 'https://goo.gl/maps/2gcj2wYPDsPYi1yn9';
  const mapsEventObject = generateContactEventTrackingObject(
    'maps',
    'contact_page_maps_link',
    mapsURL
  );
  const handlsMapsOnClick = React.useCallback((): void => {
    /* istanbul ignore next */
    if (!tag) return;
    tag(mapsEventObject);
  }, [tag, mapsEventObject]);

  const emailURL = `mailto:${props.email}`;
  const emailEventObject = generateContactEventTrackingObject(
    'email',
    'contact_page_email_link',
    emailURL
  );
  const handleEmailOnClick = React.useCallback((): void => {
    /* istanbul ignore next */
    if (!tag) return;
    tag(emailEventObject);
  }, [tag, emailEventObject]);

  return (
    <Background>
      <Layout>
        <p>
          <PhoneNumberLink href={phoneURL} onClick={handlePhoneOnClick}>
            <IconPhoneHandset scale={1.5} />
            {props.phoneNumber.replace(/\d{2}(?=.)/g, '$& ')}
          </PhoneNumberLink>
        </p>
        <p>
          <MarkerLink
            href={mapsURL}
            target="_blank"
            rel="noopener noreferrer"
            onClick={handlsMapsOnClick}
          >
            <IconMapMarker scale={1.5} /> 5 Allée du Colvert, 44000 Nantes
          </MarkerLink>
        </p>
        <p style={{ marginBottom: scale(2) }}>
          <Link href={emailURL} onClick={handleEmailOnClick}>
            <IconMail scale={1.5} /> {props.email}
          </Link>
        </p>
        <SchedulesTable>
          <caption>Horaires</caption>
          <thead>
            <tr>
              <th>
                <Mask>Jour de la semaine</Mask>
              </th>
              <th scope="col">
                <Mask>Horaires d&apos;ouverture</Mask>
              </th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(i18nDays).map((day) => {
              const openingHour = props.openingHours.find((openingHour) =>
                openingHour.days?.includes(day)
              );

              return (
                <tr key={day}>
                  <th>{i18nDays[day]}</th>
                  <td>
                    {openingHour
                      ? `${openingHour.openingTime} - ${openingHour.closingTime}`
                      : day === 'Saturday'
                      ? 'Sur demande'
                      : 'Fermé'}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </SchedulesTable>
      </Layout>
    </Background>
  );
};

export default ContactInformation;
